import TopNavbar from "./../../components/TopNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import House from "../../assets/house.png";
import "./AreaVistoriaPage.css";
import "../../components/Loading.css";
import ItemVistoria from "../../components/ItemVistoria";
import Logo from "../../assets/logo.png";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
    getItens,
    createItens,
    createVistoria,
    deleteItens
} from "../../services/api";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";

// const condId = "63cfef1490af8b72c706fe5f"
// const areaId = "63d17115a3b21f3c82cbbdde"

function AreaVistoriaPage() {
    const { user } = useContext(AuthContext);
    const { areaId, area_name } = useParams();

    const [itens, setItens] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);

    const [values, setValues] = useState([]);
    const [nomeMorador, setNomeMorador] = useState("");
    const [numeroApartamento, setNumeroApartamento] = useState("");
    const [emailMorador, setEmailMorador] = useState("");
    const [observacoes, setObservacoes] = useState("");

    const [entrada, setEntrada] = useState(false);
    const [saida, setSaida] = useState(false);
    const [corretiva, setCorretiva] = useState(false);
    const [preventiva, setPreventiva] = useState(false);

    const [alertMessage, setAlertMessage] = useState(false);
    // Criar um objeto que contém as subáreas e os itens de cada subárea
    const subAreasObj = itens.reduce((acc, item) => {
        // Verifica se a subárea do item já foi adicionada ao objeto
        if (!acc[item.subAreaName]) {
            // Se a subárea ainda não foi adicionada, cria um novo array para ela
            acc[item.subAreaName] = [];
        }

        // Adiciona o item ao array da subárea correspondente
        acc[item.subAreaName].push(item);

        return acc;
    }, {});

    // adiciona um novo item
    const handleNewItem = async (name, subAreaName) => {
        console.log("new item", name, subAreaName);
        try {
            // Cria um novo objeto com as informações do novo item
            const newItem = {
                name,
                subAreaName
            };

            // Verifica se a subárea do novo item já foi adicionada ao objeto
            if (!subAreasObj[newItem.subAreaName]) {
                subAreasObj[newItem.subAreaName] = [];
            }

            // Adiciona o novo item ao array da subárea correspondente
            subAreasObj[newItem.subAreaName].push(newItem);

            // Chama a função para criar o novo item na API
            await createItens(user.condId, areaId, subAreaName, name);
            await loadData();
        } catch (err) {
            console.error(err);
        }
    };

    const handleDeleteItem = async (item) => {
        await deleteItens(item.condId, item.areaId, item.subAreaName, item._id);
        await loadData();
    };

    // captura o valor dos inputs de cada item
    function handleInputChange(e) {
        const { name, value } = e.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    // seta o valor dos inputs radio
    const [radioData, setRadioData] = useState({
        value1: "confere",
        value2: "não confere",
        selectedRadioInput: ""
    });

    // captura o valor dos inputs radio
    const handleRadioChange = (e) => {
        const { name, value, id } = e.target;
        if (value === "não confere") {
            setCheckedItems([...checkedItems, name]);
            setRadioData({ ...radioData, [name]: value });
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== name));
            setRadioData({ ...radioData, [name]: value });
        }
    };

    const [selectedFile1, setSelectedFile1] = useState({});
    const [selectedFile2, setSelectedFile2] = useState({});    

    const handleFile1Change = (event, itemId) => {
        const newFile = event.target.files[0];

        setSelectedFile1({
            itemId,
            file: newFile,
            fileName: newFile.name
        });
    }

    const handleFile2Change = (event, itemId) => {
        const newFile = event.target.files[0];

        setSelectedFile2({
            itemId,
            file: newFile,
            fileName: newFile.name
        });
    };

    const handleEntradaChange = (event) => {
        setEntrada(event.target.checked);
    };

    const handleSaidaChange = (event) => {
        setSaida(event.target.checked);
    };

    const handlePreventivaChange = (event) => {
        setPreventiva(event.target.checked);
    };

    const handleCorretivaChange = (event) => {
        setCorretiva(event.target.checked);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();

        // Adicione as imagens selecionadas ao formulário

        let selectedFiles = [selectedFile1, selectedFile2];        

        selectedFiles.forEach((file) => {
            formData.append("images", file.file);
        });

        const itensData = itens.map((item) => {
            //verifica se o item é um objeto vazio
            if (item !== undefined && Object.keys(item).length === 0) {
                return {};
            }

            //file aqui é um array de objetos, com todos os arquivos selecionados de cada item das subareas
            const selectedItemFiles = selectedFiles.filter(
                (file) => {
                    if (file !== undefined && Object.keys(file).length !== 0) {
                        return file.itemId._id === item._id;
                    }
                    else {
                        return false;
                    }
                }
            );

            return {
                name: item.name,
                subAreaName: item.subAreaName,
                quantity: values[`quantidade_${item._id}`] || 0,
                obs: values[`obs_${item._id}`] || "",
                confere: radioData[item._id] || "não informado",
                files: (selectedItemFiles && selectedItemFiles.length > 0) ? selectedItemFiles.map(item => item.fileName) : []
            };
        });

        // Adicione os dados do formulário ao formulário
        formData.append("nomeMorador", nomeMorador);
        formData.append("numeroApartamento", numeroApartamento);
        formData.append("emailMorador", emailMorador);
        formData.append("observacoes", observacoes);
        formData.append("itensData", JSON.stringify(itensData));
        formData.append("entrada", entrada);
        formData.append("saida", saida);
        formData.append("preventiva", preventiva);
        formData.append("corretiva", corretiva);

        try {
            setLoading(true);
            await createVistoria(user.condId, areaId, area_name, formData);
            setLoading(false);
            setAlertMessage(true);
        } catch (err) {
            return console.log(err);
        }
    };

    // carrega os itens na pagina
    const loadData = async () => {
        try {
            setLoading(true);
            const responseItens = await getItens(user.condId, areaId);
            setItens(responseItens.data);
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoadingError(true);
        }
    };

    useEffect(() => {
        (async () => await loadData())();
    }, []);

    if (loadingError) {
        return (
            <div className="loading">
                Erro ao carregar itens da vistoria.{" "}
                <Link to={`/${user.condId}/`}>Voltar</Link>
            </div>
        );
    }

    if (loading) {
        return <div className="loading">carregando...</div>;
    }

    return (
        <div className="user-menu">
            <TopNavbar />
            <div className="area">
                <div className="container px-4 py-5" id="hanging-icons">
                    <div className="row">
                        <div className=" col text-center top-item">
                            <div className="circle">
                                <img
                                    className="item-icon"
                                    src={House}
                                    alt="icon"
                                />
                            </div>
                        </div>
                        <div className="text-center">
                            <h3>{area_name}</h3>
                        </div>
                    </div>
                    <div className="container">
                        <ItemVistoria onNewItem={handleNewItem} />
                    </div>

                    <form onSubmit={handleFormSubmit}>
                        <div className="container ">
                            {Object.entries(subAreasObj).map(
                                ([subAreaName, subAreaItens]) => (
                                    <div key={subAreaName}>
                                        <h2 className="subarea-title pb-2 mt-5 ">
                                            {subAreaName}
                                        </h2>
                                        <div className="row">
                                            {subAreaItens.map((item) => (
                                                <div
                                                    className={`col p-4 m-3 item d-flex flex-column ${
                                                        checkedItems.includes(
                                                            item._id
                                                        )
                                                            ? "border-danger"
                                                            : ""
                                                    }`}
                                                    id={item.name}
                                                    key={item.name}
                                                >
                                                    <button
                                                        className="delete-item"
                                                        type="button"
                                                        onClick={() =>
                                                            handleDeleteItem(
                                                                item
                                                            )
                                                        }
                                                    >
                                                        X
                                                    </button>
                                                    <h4>{item.name}</h4>
                                                    <div className="input-group py-2">
                                                        <span className="input-group-text">
                                                            Peças
                                                        </span>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name={`quantidade_${item._id}`}
                                                            value={
                                                                values[
                                                                    `quantidade_${item._id}`
                                                                ]
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={item._id}
                                                            id={`confere${item._id}`}
                                                            value={
                                                                radioData.value1
                                                            }
                                                            onChange={
                                                                handleRadioChange
                                                            }
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            for={`confere${item._id}`}
                                                        >
                                                            Confere
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name={item._id}
                                                            id={`nao_confere${item._id}`}
                                                            value={
                                                                radioData.value2
                                                            }
                                                            onChange={
                                                                handleRadioChange
                                                            }
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            for={`nao_confere${item._id}`}
                                                        >
                                                            Não confere
                                                        </label>
                                                    </div>
                                                    <div className=" py-2">
                                                        <label
                                                            htmlFor="exampleFormControlTextarea1"
                                                            className="form-label"
                                                        >
                                                            Observação:
                                                        </label>
                                                        <textarea
                                                            className="form-control d-inline"
                                                            id="exampleFormControlTextarea1"
                                                            placeholder="observações..."
                                                            rows="3"
                                                            name={`obs_${item._id}`}
                                                            value={
                                                                values[
                                                                    `obs_${item._id}`
                                                                ]
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        ></textarea>
                                                    </div>
                                                    <div className="py-2">
                                                        <div
                                                            className="mb-3"
                                                        >
                                                            <label
                                                                htmlFor="formFile"
                                                                className="form-label"
                                                            >
                                                                Escolha o arquivo 1
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                name="images"                                                                
                                                                value={
                                                                    values[
                                                                        `file_${item._id}`
                                                                    ]
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    handleFile1Change(
                                                                        event,
                                                                        item
                                                                    )
                                                                }
                                                                accept="image/*,video/*"
                                                                type="file"
                                                                id="formFile"                                                                
                                                            />
                                                            <label
                                                                htmlFor="formFile"
                                                                className="form-label"
                                                            >
                                                                Escolha o arquivo 2
                                                            </label>

                                                            <input
                                                                className="form-control"
                                                                name="images"                                                                
                                                                value={
                                                                    values[
                                                                        `file_${item._id}`
                                                                    ]
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    handleFile2Change(
                                                                        event,
                                                                        item
                                                                    )
                                                                }
                                                                accept="image/*,video/*"
                                                                type="file"
                                                                id="formFile2"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )
                            )}
                            <div className="container">
                                <div className="row">
                                    <div className="col form-vistoria">
                                        <div className="mb-3 pt-3 ">
                                            <input
                                                type="text"
                                                className="form-control "
                                                id="exampleFormControlInput1"
                                                placeholder="Nome do morador"
                                                value={nomeMorador}
                                                onChange={(e) =>
                                                    setNomeMorador(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="mb-3 pt-3 ">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="numero do apartamento e bloco ex: 123-A"
                                                value={numeroApartamento}
                                                onChange={(e) =>
                                                    setNumeroApartamento(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="mb-3  pb-3">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="exampleFormControlInput1"
                                                placeholder="Email do morador"
                                                value={emailMorador}
                                                onChange={(e) =>
                                                    setEmailMorador(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>

                                        <div className="box-checkbox">
                                            <div className="box-title">
                                                VISTORIA
                                            </div>
                                            <div className='box-container' >
                                                <div className="a-checkbox">
                                                    <label class="container-checkbox">
                                                        Entrada
                                                        <input
                                                            onChange={
                                                                handleEntradaChange
                                                            }
                                                            type="checkbox"
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>

                                                    <label className="container-checkbox">
                                                        Saída
                                                        <input
                                                            onChange={
                                                                handleSaidaChange
                                                            }
                                                            type="checkbox"
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                                <div className="a-checkbox">
                                                    <label className="container-checkbox">
                                                        Corretiva
                                                        <input
                                                            onChange={
                                                                handleCorretivaChange
                                                            }
                                                            type="checkbox"
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>

                                                    <label className="container-checkbox">
                                                        Preventiva
                                                        <input
                                                            onChange={
                                                                handlePreventivaChange
                                                            }
                                                            type="checkbox"
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div
                                                style={{ display: "none" }}
                                                className="box-radio"
                                            >
                                                <label class="container-radio">
                                                    Entrada Corretiva
                                                    <input
                                                        type="radio"
                                                        name="entrada"
                                                        value="Corretiva"
                                                        // checked={
                                                        //     entrada ===
                                                        //     "corretiva"
                                                        // }
                                                        onChange={
                                                            handleEntradaChange
                                                        }
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label class="container-radio">
                                                    Entrada Preventiva
                                                    <input
                                                        type="radio"
                                                        name="entrada"
                                                        value="Preventiva"
                                                        // checked={
                                                        //     entrada ===
                                                        //     "preventiva"
                                                        // }
                                                        onChange={
                                                            handleEntradaChange
                                                        }
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="container-radio">
                                                    Saída Corretiva
                                                    <input
                                                        type="radio"
                                                        name="saida"
                                                        value="Corretiva"
                                                        // checked={
                                                        //     saida ===
                                                        //     "corretiva"
                                                        // }
                                                        onChange={
                                                            handleSaidaChange
                                                        }
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="container-radio">
                                                    Saída Preventiva
                                                    <input
                                                        type="radio"
                                                        name="saida"
                                                        value="Preventiva"
                                                        // checked={
                                                        //     saida ===
                                                        //     "preventiva"
                                                        // }
                                                        onChange={
                                                            handleSaidaChange
                                                        }
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="mb-3 pb-3">
                                            <textarea
                                                className="form-control"
                                                id="exampleFormControlTextarea1"
                                                rows="3"
                                                placeholder="observações..."
                                                value={observacoes}
                                                onChange={(e) =>
                                                    setObservacoes(
                                                        e.target.value
                                                    )
                                                }
                                            ></textarea>
                                        </div>
                                        <div className="d-grid gap-2 pb-3">
                                            <button
                                                className="btn btn-primary"
                                                type="submit"
                                            >
                                                Enviar
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col  form-vistoria-image">
                                        <img
                                            className="vistoria-image"
                                            src={Logo}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <Alert
                show={alertMessage}
                variant="success"
                className="custom-alert"
            >
                <Alert.Heading className="alert-title">
                    Vistoria Realizada com sucesso!
                </Alert.Heading>
                <hr />
                <div className="d-flex justify-content-end">
                    <Button
                        onClick={() => {
                            window.location.replace(`/${user.condId}`);
                        }}
                        variant="outline-success"
                    >
                        ok!
                    </Button>
                </div>
            </Alert>
        </div>
    );
}

export default AreaVistoriaPage;
